import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CasesBlock from "../componentsNonAdmin/Expertise/index"
import { renderHtmlBlocks } from "../helpers/render"
import { getCustomStyles } from "../helpers/styles"

const CasesPage = ({ data, location, pageContext }) => {
  const { t } = useTranslation()
  const siteTitle = data.site.siteMetadata.title
  const content = data?.wpPage?.content
  const seo = data?.wpPage?.seo
  const settings = data?.wpPage?.settings

  return (
    <Layout
      location={location}
      title={siteTitle}
      description={seo?.metaDescription || ""}
      keywords={seo?.metaKeywords || ""}
    >
      <Seo
        title={seo?.title || t("Наши проекты - eRetail платформа DV Group")}
        description={seo?.metaDescription || ""}
        keywords={seo?.metaKeywords || ""}
      />
      {getCustomStyles(settings)}
      {content && renderHtmlBlocks(content)}
      <CasesBlock showMore={true} pageContext={pageContext} fromCase={true} />
    </Layout>
  )
}

export default CasesPage

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "cases" }, status: { eq: "publish" }) {
      content
      seo {
        metaDescription
        metaKeywords
        title
      }
      settings {
        customStyles
        rbeaStyles
        language
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
