// extracted by mini-css-extract-plugin
export var boxFromCases = "Expertise-module--boxFromCases--efb3b";
export var boxFromCasesItem = "Expertise-module--boxFromCasesItem--2bfcd";
export var caseBody = "Expertise-module--case-body--da0f9";
export var caseDescription = "Expertise-module--case-description--8573b";
export var caseHead = "Expertise-module--case-head--3fc16";
export var caseIconWrapper = "Expertise-module--case-icon-wrapper--a3bdc";
export var caseImage = "Expertise-module--case-image--324ab";
export var caseLink = "Expertise-module--case-link--c315c";
export var caseResult = "Expertise-module--case-result--13a1c";
export var caseResultDescription = "Expertise-module--case-result-description--30067";
export var caseResultTitle = "Expertise-module--case-result-title--3eee1";
export var casesSlider = "Expertise-module--cases-slider--c4ca9";
export var container = "Expertise-module--container--7c2b0";
export var flexWrapOnMobile = "Expertise-module--flexWrapOnMobile--1ba84";
export var images = "Expertise-module--images--14b99";
export var moreWrapper = "Expertise-module--more-wrapper--74dfe";
export var sectionTitle = "Expertise-module--section-title--f1472";
export var showMoreBtn = "Expertise-module--showMoreBtn--08ead";
export var showMoreBtnBox = "Expertise-module--showMoreBtnBox--fca50";
export var showMoreSection = "Expertise-module--show-more-section--2a287";
export var slide = "Expertise-module--slide--2c483";
export var wrapper = "Expertise-module--wrapper--42b80";