import { useEffect, useState } from "react"

function useMediaQuery(query) {
  const [matches, setMatches] = useState(
    typeof window !== "undefined" && window.matchMedia(query).matches
  )

  useEffect(() => {
    const mediaQueryList =
      typeof window !== "undefined" && window.matchMedia(query)
    const listener = event => setMatches(event.matches)

    mediaQueryList.addEventListener("change", listener)

    return () => mediaQueryList.removeEventListener("change", listener)
  }, [query])

  return matches
}

export default useMediaQuery
